















































































import { defineComponent } from '@nuxtjs/composition-api';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import ModalHeading from '@/components/molecules/ModalHeading.vue';
import Link from '~/components/atoms/Link/Link.vue';
import { useExtraGuarantee, useCartExtended, useExtraGuaranteePrice } from '~/composables';

export default defineComponent({
  name: 'ExtraGuaranteeInner',
  components: {
    Button,
    ImgView,
    ModalHeading,
    Link
  },
  setup(_props, { emit }) {
    const { getSkuForExtraGuarantee } = useExtraGuarantee();
    const { extraGuaranteePriceFormatted } = useExtraGuaranteePrice();

    const {
      addExtraGuaranteeProduct
    } = useCartExtended();

    const handleAcceptExtraGuarantee = async () => {
      await addExtraGuaranteeProduct(getSkuForExtraGuarantee());
      emit('clickClose');
    };

    return {
      handleAcceptExtraGuarantee,
      extraGuaranteePriceFormatted
    };
  }
});

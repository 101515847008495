












import { defineComponent } from '@nuxtjs/composition-api';
import SideBar from '~/components/organisms/SideBar/SideBar.vue';
import { useUiState } from '~/composables';
import ExtraGuaranteeInner from '~/components/organisms/ExtraGuarantee/ExtraGuaranteeInner/ExtraGuaranteeInner.vue';

export default defineComponent({
  name: 'ExtraGuaranteeSidebar',
  components: {
    SideBar,
    ExtraGuaranteeInner
  },
  setup() {
    const { isExtraGuaranteeOpen, toggleExtraGuarantee } = useUiState();

    return {
      isExtraGuaranteeOpen,
      toggleExtraGuarantee
    };
  }
});
